import React, { useState } from 'react';
import Link from 'next/link';

const NavAccordion = ({ heading, content, path, setShowMobileNav }) => {
  const [isActive, setIsActive] = useState(false);
  return (
    <li className="w-full p-2 pt-4 border-b border-gray-200">
      <div className="flex justify-between w-full" onClick={() => setIsActive(!isActive)}>
        <Link onClick={() => setShowMobileNav(false)} href={`/${path}`} className="font-bold ">
          {heading}
        </Link>
        {content.length > 0 ? <div>{isActive ? '-' : '+'}</div> : null}
      </div>
      {content.length > 0 && isActive ? (
        <div className="grid pl-2">
          {content?.map((it) => {
            return (
              <Link onClick={() => setShowMobileNav(false)} key={JSON.stringify(it)} href={`/${it.path}`}>
                {it.title}
              </Link>
            );
          })}
        </div>
      ) : null}
    </li>
  );
};

export default NavAccordion;
