import React from 'react';
export function Button({ children, styles = ' ', size = 'base' }) {
  let baseStyle =
    'bg-fyllo-800 rounded-lg px-4 flex items-center justify-between py-1 hover:bg-green-700 active:bg-fyllo-500 text-white border-none';
  switch (size) {
    case 'lg':
      baseStyle =
        'text-lg my-2 rounded-lg  bg-fyllo-800 px-6 py-3  font-bold text-white hover:bg-green-700 transition-all ';
      break;
    case 'base':
    default:
      baseStyle =
        'bg-fyllo-800 rounded-lg px-4 flex items-center justify-between py-1 hover:bg-green-700 active:bg-fyllo-500 text-white border-none';
      break;
  }
  return <button className={baseStyle + ' ' + styles}>{children}</button>;
}

export default Button;
