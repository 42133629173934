import React from 'react';
import Head from 'next/head';

export default function MetaHead({
  title = 'Fyllo',
  description = 'Fyllo is a next gen agriculture technology company that hepling farmer with precision farming ',
  keywords = 'Agriculture, IoT,Fyllo, Indian farmers, Smart farms, Smart Farming, New Techniques in farming, Fertilizers,Fertilizers Application,Ag revolution, Agritech, Agritech startup, AI, Smart Agriculture, Precision Agriculture, Soil Moisture, weather Prediction, Disease Prediction, Data Driven Agriculture,which are fyllo products, products of fyllo, fyllo hardware, fyllo software, fyllo software products, fyllo hardware products, fyllo Nero, fyllo Kairo, fyllo irrigation unit, fyllo main unit, fyllo weather station, weather station, irrigation management, disease management, pest management, fyllo devices, what are fyllo devices, fyllo unit, Kairo by fyllo, nero by fyllo',
}) {
  return (
    <Head>
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta name="keywords" content={keywords} />
      <meta name="robots" content="index, follow" />
      <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
      <meta name="language" content="English" />
      <meta name="revisit-after" content="30 days" />
      <meta name="author" content="Fyllo " />
      <link rel="manifest" href="/site.webmanifest" />

      <title>{title}</title>
      <meta name="description" content={description} />

      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://fyllo.in/" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content="/fyllo-icon.png" />

      <meta name="twitter:card" content="summary" />
      <meta name="twitter:site" content="@site_account" />
      <meta name="twitter:creator" content="@fylloTech" />
      <meta name="twitter:url" content="https://twitter.com/fyllotech?lang=en" />
      <meta property="twitter:title" content={title} />
      <meta property="twitter:description" content={description} />
      <meta property="twitter:image" content="/fyllo-icon.png" />
    </Head>
  );
}
