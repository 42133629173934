import '@/styles/globals.css';
import Navbar from '@/Components/Navbar';
import Footer from '@/Components/Footer';
import Image from 'next/image';
import { AnimatePresence, motion } from 'framer-motion';
import MetaHead from '@/Components/MetaHead';
import { useEffect } from 'react';
import ChatWithUs from '../../public/chatWithUs.png';

export default function App({ Component, pageProps }) {
  useEffect(() => {
    const loadScripts = async () => {
      try {
        await loadScript('https://code.jquery.com/jquery-3.6.0.min.js');
        await loadScript('https://zammad.fyllo.in/assets/chat/chat.min.js');
        window.$(function () {
          new window.ZammadChat({
            background: '#0c9611',
            fontSize: '12px',
            chatId: 1,
            show: false,
          });
        });
      } catch (error) {
        console.error('Error loading scripts:', error);
      }
    };

    loadScripts();

    return () => {
    };
  }, []);

  const loadScript = (src) => {
    return new Promise((resolve, reject) => {
      const script = document.createElement('script');
      script.src = src;
      script.async = true;
      script.onload = resolve;
      script.onerror = reject;
      document.body.appendChild(script);
    });
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <motion.div className="pt-24 scroll-smooth transition-all">
      <MetaHead />
      <Navbar />
      <Component {...pageProps} />
      <Footer />
      <div className="md:block z-50 fixed bottom-20 right-6 open-zammad-chat" aria-label="Open Zammad Chat">
        <div className="h-14 w-14 fill-fyllo-800 rounded-full bg-white p-3 shadow-xl">
          <Image src={ChatWithUs} alt="Talk to us" sizes="(max-width: 768px) 115vw, (max-width: 1200px) 60vw, 53vw" />
        </div>
      </div>

      <button className="hidden md:block z-50 fixed bottom-8 right-6" onClick={scrollToTop} aria-label="scroll to top">
        <svg
          className="h-10 w-10 fill-fyllo-800 rounded-full bg-white p-2 shadow-xl"
          version="1.1"
          id="Layer_1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 199.404 199.404"
        >
          <g>
            <polygon points="0,135.411 28.285,163.695 99.703,92.277 171.119,163.695 199.404,135.412 99.703,35.709 " />
          </g>
        </svg>
      </button>
    </motion.div>
  );
}
