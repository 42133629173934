import React from 'react';
import logo from '../assets/fyllo.png';

import Link from 'next/link';
import Image from 'next/image';
import ScrollLink from './ScrollLink';

export default function Footer() {
  return (
    <footer className="p-8 bg-slate-100 md:p-14  relative z-10">
      <div className="gap-4 md:flex md:justify-between">
        <div className="mb-10 md:mb-10">
          <Link href="https://fyllo.in/" className="flex items-center justify-center w-full">
            <Image
              src={logo}
              className="self-center aspect-auto w-36 md:w-44 mr-3"
              alt="Fyllo Logo"
              sizes="(max-width: 768px) 100vw,
              (max-width: 1200px) 50vw,
              33vw"
            />
          </Link>
          <div id="store_container" className="flex flex-row justify-center gap-2 mt-4 md:justify-between">
            <Link
              target="_blank"
              rel="noopener noreferrer"
              href={'https://play.google.com/store/apps/details?id=com.fyllo.fyllo&hl=en_IN&gl=US'}
              className="flex mt-3 w-28 h-10 bg-black text-white rounded items-center justify-center hover:text-slate-100"
            >
              <div className="mr-3">
                <svg viewBox="30 336.7 120.9 129.2" width="18">
                  <path
                    fill="#FFD400"
                    d="M119.2,421.2c15.3-8.4,27-14.8,28-15.3c3.2-1.7,6.5-6.2,0-9.7  c-2.1-1.1-13.4-7.3-28-15.3l-20.1,20.2L119.2,421.2z"
                  />
                  <path
                    fill="#FF3333"
                    d="M99.1,401.1l-64.2,64.7c1.5,0.2,3.2-0.2,5.2-1.3  c4.2-2.3,48.8-26.7,79.1-43.3L99.1,401.1L99.1,401.1z"
                  />
                  <path
                    fill="#48FF48"
                    d="M99.1,401.1l20.1-20.2c0,0-74.6-40.7-79.1-43.1  c-1.7-1-3.6-1.3-5.3-1L99.1,401.1z"
                  />
                  <path
                    fill="#3BCCFF"
                    d="M99.1,401.1l-64.3-64.3c-2.6,0.6-4.8,2.9-4.8,7.6  c0,7.5,0,107.5,0,113.8c0,4.3,1.7,7.4,4.9,7.7L99.1,401.1z"
                  />
                </svg>
              </div>
              <div>
                <div className="text-white text-[0.5rem]">GET IT ON</div>
                <div className="text-white text-xs font-semibold font-sans -mt-1">Google Play</div>
              </div>
            </Link>
            <Link
              target="_blank"
              rel="noopener noreferrer"
              href={'https://apps.apple.com/us/app/fyllo/id1441651706'}
              className="flex mt-3 w-28 h-10 bg-slate-200 text-black  border-black rounded items-center justify-center hover:text-slate-900 "
            >
              <div className="mr-3">
                <svg viewBox="0 0 384 512" width="18">
                  <path
                    fill="currentColor"
                    d="M318.7 268.7c-.2-36.7 16.4-64.4 50-84.8-18.8-26.9-47.2-41.7-84.7-44.6-35.5-2.8-74.3 20.7-88.5 20.7-15 0-49.4-19.7-76.4-19.7C63.3 141.2 4 184.8 4 273.5q0 39.3 14.4 81.2c12.8 36.7 59 126.7 107.2 125.2 25.2-.6 43-17.9 75.8-17.9 31.8 0 48.3 17.9 76.4 17.9 48.6-.7 90.4-82.5 102.6-119.3-65.2-30.7-61.7-90-61.7-91.9zm-56.6-164.2c27.3-32.4 24.8-61.9 24-72.5-24.1 1.4-52 16.4-67.9 34.9-17.5 19.8-27.8 44.3-25.6 71.9 26.1 2 49.9-11.4 69.5-34.3z"
                  />
                </svg>
              </div>
              <div>
                <div className="text-[0.5rem]">Download on the</div>
                <div className="text-xs font-semibold font-sans -mt-1">App Store</div>
              </div>
            </Link>
          </div>
        </div>
        <div className="grid grid-cols-2 gap-8 sm:gap-6 sm:grid-cols-3">
          <div>
            <h3 className="mb-6 text-sm font-semibold text-gray-900 uppercase ">Company</h3>
            <ul className="text-slate-800 ">
              <li className="mb-2">
                <Link href={'/about'} className="hover:underline text-black">
                  About Us
                </Link>
              </li>
              <li className="mb-2">
                <Link href="/contact#contact_form" className="hover:underline text-black">
                  Contact Us
                </Link>
              </li>
              <li className="mb-2">
                <a href={'/#howItWorks'} className="hover:underline text-black">
                  How It Works
                </a>
              </li>
              <li className="mb-2">
                <Link href={'/careers'} className="hover:underline text-black">
                  Careers
                </Link>
              </li>
            </ul>
          </div>
          <div>
            <h3 className="mb-6 text-sm font-semibold text-gray-900 uppercase ">Explore</h3>

            <ul className="text-slate-800 ">
              <li className="mb-2">
                <Link href={'/usecases'} className="hover:underline text-black">
                  Use Cases
                </Link>
              </li>
              <li className="mb-2">
                <Link href={'/product'} className="hover:underline text-black">
                  Products
                </Link>
              </li>
              <li className="mb-2">
                <Link href="/research" className="hover:underline text-black">
                  Research
                </Link>
              </li>
              <li className="mb-2">
                <Link href={'/blogs'} className="hover:underline text-black">
                  Blogs
                </Link>
              </li>
              <li className="mb-2">
                <Link href={'/faq'} className="hover:underline text-black">
                  FAQ
                </Link>
              </li>
            </ul>
          </div>
          <div>
            <h3 className="mb-6 text-sm font-semibold text-gray-900 uppercase ">Legal</h3>
            <ul className="text-slate-800 ">
              <li className="mb-2">
                <Link href="/app-terms-of-service" className="hover:underline text-black">
                  Terms &amp; Conditions
                </Link>
              </li>
              <li className="mb-2">
                <Link href="/app-policies" className="hover:underline text-black">
                  Privacy Policy
                </Link>
              </li>
              <li className="mb-2">
                <Link href="/refund-policy" className="hover:underline text-black">
                  Refund Policies
                </Link>
              </li>
              <li className="mb-2">
                <Link href="/shipping" className="hover:underline text-black">
                  Shipping &amp; Exchange
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <hr className="my-6 border-gray-200 sm:mx-auto  lg:my-8" />
      <div className="sm:flex sm:items-center sm:justify-between">
        <span className="text-sm text-gray-700 sm:text-center ">
          © {new Date().getFullYear()}{' '}
          <Link href="https://fyllo.in/" className="hover:underline text-black">
            Fyllo™
          </Link>
          . All Rights Reserved.
        </span>
        <div className="flex mt-4 space-x-6 sm:justify-center sm:mt-0">
          <Link
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.facebook.com/FylloTech/"
            className="text-gray-700 hover:text-gray-900 "
          >
            <svg
              className="w-5 h-5 hover:fill-fyllo-800 hover:scale-[1.2] transition-all"
              fill="currentColor"
              viewBox="0 0 24 24"
              aria-hidden="true"
            >
              <path
                fillRule="evenodd"
                d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                clipRule="evenodd"
              />
            </svg>
            <span className="sr-only">Fyllo Facebook page</span>
          </Link>
          <Link
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.instagram.com/fyllotech/"
            className="text-gray-700 hover:text-gray-900 "
          >
            <svg
              className="w-5 h-5 hover:fill-fyllo-800 hover:scale-[1.2] transition-all"
              fill="currentColor"
              viewBox="0 0 24 24"
              aria-hidden="true"
            >
              <path
                fillRule="evenodd"
                d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
                clipRule="evenodd"
              />
            </svg>
            <span className="sr-only">Fyllo Instagram page</span>
          </Link>
          <Link
            target="_blank"
            rel="noopener noreferrer"
            href="https://twitter.com/fyllotech"
            className="text-gray-700 hover:text-gray-900 "
          >
            <svg
              className="w-5 h-5 hover:fill-fyllo-800 hover:scale-[1.2] transition-all"
              fill="currentColor"
              viewBox="0 0 24 24"
              aria-hidden="true"
            >
              <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
            </svg>
            <span className="sr-only">Fyllo Twitter page</span>
          </Link>
          <Link
            target="_blank"
            rel="noopener noreferrer"
            href="https://in.linkedin.com/company/fyllo"
            className="text-gray-700 hover:text-gray-900"
          >
            <svg
              className="w-5 h-5  hover:fill-fyllo-800 hover:scale-[1.2] transition-all"
              fill="currentColor"
              aria-hidden="true"
            >
              <path d="M18.72 3.99997H5.37C5.19793 3.99191 5.02595 4.01786 4.86392 4.07635C4.70189 4.13484 4.55299 4.22471 4.42573 4.34081C4.29848 4.45692 4.19537 4.59699 4.12232 4.75299C4.04927 4.909 4.0077 5.07788 4 5.24997V18.63C4.01008 18.9901 4.15766 19.3328 4.41243 19.5875C4.6672 19.8423 5.00984 19.9899 5.37 20H18.72C19.0701 19.9844 19.4002 19.8322 19.6395 19.5761C19.8788 19.32 20.0082 18.9804 20 18.63V5.24997C20.0029 5.08247 19.9715 4.91616 19.9078 4.76122C19.8441 4.60629 19.7494 4.466 19.6295 4.34895C19.5097 4.23191 19.3672 4.14059 19.2108 4.08058C19.0544 4.02057 18.8874 3.99314 18.72 3.99997ZM9 17.34H6.67V10.21H9V17.34ZM7.89 9.12997C7.72741 9.13564 7.5654 9.10762 7.41416 9.04768C7.26291 8.98774 7.12569 8.89717 7.01113 8.78166C6.89656 8.66615 6.80711 8.5282 6.74841 8.37647C6.6897 8.22474 6.66301 8.06251 6.67 7.89997C6.66281 7.73567 6.69004 7.57169 6.74995 7.41854C6.80986 7.26538 6.90112 7.12644 7.01787 7.01063C7.13463 6.89481 7.2743 6.80468 7.42793 6.74602C7.58157 6.68735 7.74577 6.66145 7.91 6.66997C8.07259 6.66431 8.2346 6.69232 8.38584 6.75226C8.53709 6.8122 8.67431 6.90277 8.78887 7.01828C8.90344 7.13379 8.99289 7.27174 9.05159 7.42347C9.1103 7.5752 9.13699 7.73743 9.13 7.89997C9.13719 8.06427 9.10996 8.22825 9.05005 8.3814C8.99014 8.53456 8.89888 8.6735 8.78213 8.78931C8.66537 8.90513 8.5257 8.99526 8.37207 9.05392C8.21843 9.11259 8.05423 9.13849 7.89 9.12997ZM17.34 17.34H15V13.44C15 12.51 14.67 11.87 13.84 11.87C13.5822 11.8722 13.3313 11.9541 13.1219 12.1045C12.9124 12.2549 12.7546 12.4664 12.67 12.71C12.605 12.8926 12.5778 13.0865 12.59 13.28V17.34H10.29V10.21H12.59V11.21C12.7945 10.8343 13.0988 10.5225 13.4694 10.3089C13.84 10.0954 14.2624 9.98848 14.69 9.99997C16.2 9.99997 17.34 11 17.34 13.13V17.34Z" />
            </svg>

            <span className="sr-only">Fyllo Linkedin account</span>
          </Link>
          <Link
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.youtube.com/channel/UCpcRX7OQUrGWDGf7I_0lePg"
            className="text-gray-700 hover:text-gray-900 "
          >
            <svg
              className="w-6 h-6 hover:fill-fyllo-800 hover:scale-[1.2] transition-all"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
            >
              <path d="m10 15l5.19-3L10 9v6m11.56-7.83c.13.47.22 1.1.28 1.9c.07.8.1 1.49.1 2.09L22 12c0 2.19-.16 3.8-.44 4.83c-.25.9-.83 1.48-1.73 1.73c-.47.13-1.33.22-2.65.28c-1.3.07-2.49.1-3.59.1L12 19c-4.19 0-6.8-.16-7.83-.44c-.9-.25-1.48-.83-1.73-1.73c-.13-.47-.22-1.1-.28-1.9c-.07-.8-.1-1.49-.1-2.09L2 12c0-2.19.16-3.8.44-4.83c.25-.9.83-1.48 1.73-1.73c.47-.13 1.33-.22 2.65-.28c1.3-.07 2.49-.1 3.59-.1L12 5c4.19 0 6.8.16 7.83.44c.9.25 1.48.83 1.73 1.73Z" />
            </svg>
            <span className="sr-only">Fyllo Youtube Channel</span>
          </Link>
        </div>
      </div>
    </footer>
  );
}
